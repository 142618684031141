<template>
    <div>
        <mw-editor v-model="question.content"></mw-editor>
    </div>
</template>

<script>
import MwEditor from "@c/ui/MwEditor";
export default {
    name: "Header", 
    components: {
        MwEditor
    },
    props: {
        id: { type: String } // the id of hte question template
    }, 
    computed:{ 
        question(){
            return this.$store.state.questions.data[this.id] || {}
        }
    },

}
</script>
